import React, { useContext,useState } from 'react';
import { Layout, Input, Button, Form} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { AppContext } from '../Stores/AppStore';
import { LOGIN_API } from '../Constants/Constants';
import loginLogo from "../img/logo-white.svg";
import sidebarLogo from "../img/logo-only-dark.svg";
import { Navigate } from 'react-router-dom';
const { Header, Content,  } = Layout;

const Login = () => {
  // eslint-disable-next-line
  const [appStore, appStoreDispatch] = useContext(AppContext);
  const [userType, SetUserType]=useState("");

  const onFinish = (values) => {
    const username = values.username.trim();
    const password = values.password.trim();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "username": username,
      "password": password,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    let setLogin = false;
       fetch(LOGIN_API, requestOptions)
      .then(response => response.json())
      .then((result) => {
        if(result.authentication_status === true){
          setLogin = true;
          console.log("user login data----",result);
        }
        if(setLogin){
          const setData = async ()=>{
            await appStoreDispatch({ type: 'SET_LOGIN', payload: { isLoggedIn: true, user: {token: 'token', username: result.username, id: result.user_id, user_type:result.user_type, user_groups:result.user_groups }}});
          }
          setData();
          SetUserType(result.user_type);
        }
      })
      .catch(error => console.log('error', error));

  };
  
  if(userType==="Player"){
    return(<Navigate to="/home" />)
  }
  if(userType==="Evaluator"){
    return(<Navigate to="/evl-home" />)
  }

  return (
    <Layout className="layout">
      <Header style={{height: '8vh'}}>
        <div className='left'>
          <a href='https://attentive.ai/'>
            <img src={loginLogo} alt='Attentive Logo' />
          </a>
        </div>
      </Header>
      <Content>
        <div className="site-layout-content">
        <div style={{display:'flex',justifyContent:'center',alignItems:"center"}}><img src={sidebarLogo} alt='Attentive Logo' width="25vh" height="25vh"/><h2 className='login-header'> HATCH</h2></div>
          <div className='form-login'>
            <div style = {{backgroundColor: "#0fa61b", width: "350px", padding: "5px 60px 0 60px" }}>
              <h2 style = {{color: "white"}}>Login to your account</h2>
            </div>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              style = {{
                backgroundColor: "#edece8",
                padding: "30px 30px 3px 30px",
                width: "350px",
                alignContent: "center",
                alignItems: "center"
              }}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ]}
              >
                <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input.Password size="large" 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
          
              <Form.Item style = {{paddingLeft: "100px"}}>
                <Button type="primary" htmlType="submit" className="login-form-button" style = {{width: "90px", margin: "auto"}}>
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Content>
      
    </Layout>
  );
}

export default Login;