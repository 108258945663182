import React from "react";
import { useParams } from "react-router-dom";
import { Layout, Button, Row, Col, Breadcrumb, Carousel } from "antd";
import { HomeOutlined, } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { MODULE_API } from "../../Constants/Constants";
import SideNavbar from "../Components/SideNavbar";
const { Content } = Layout;

const Module = () => {
  const { module } = useParams();
  const [moduleText, setModuleText] = useState("");
  const [moduleSampleImageUrls, setModuleSampleImageUrls] = useState([]);
  const [moduleSampleUrls, setModuleSampleUrls] = useState([]);
  // eslint-disable-next-line
  const [moduleData, setModuleData] = useState([]);

  async function getData() {
    var inputData = await fetch(MODULE_API);
    const APIdata = await inputData.json();
    const data = await APIdata.data;
    setModuleData(data);
    console.log("inputJsonData--", data);
    data.map((eachmodule) => {
      if (eachmodule.module_name === module) {
        setModuleText(eachmodule.module_text);
        setModuleSampleImageUrls(eachmodule.module_sample_image_url);
        setModuleSampleUrls(eachmodule.module_sample_url);
      }
      return null;
    });
  }

  useEffect(
    () => {
      getData();
    },
    // eslint-disable-next-line
    []
  );

  // eslint-disable-next-line
  const [practiceDisable, setPracticeDisable] = useState(true);

  const contentStyle = {
    height: "400px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const carouselRef = React.createRef();

  return (
    <Layout className="layout">
      {/* <HeaderNavbar/> */}
      <SideNavbar />
      <Layout>
        <Content className="site-layout-content">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/">My games</Breadcrumb.Item>
              <Breadcrumb.Item>{module}</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="module-h2 green-text">OVERVIEW</h2>

            <Row
              className="module-button-row"
              justify="space-around"
              align="middle"
            >
              <Col span={4} className="text-align-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="green-button"
                  href={
                    module === "One Carto Platform(OCP)"
                      ? `/${module}/`
                      : `/${module}/tutorial`
                  }
                  disabled={module === "One Carto Platform(OCP)" ? true : false}
                >
                  Tutorial
                </Button>
              </Col>
              <Col span={4} className="text-align-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={practiceDisable}
                  href={`/${module}/practice-mode`}
                >
                  Practice Arena
                </Button>
              </Col>
              <Col span={4} className="text-align-center">
                <Button type="primary" htmlType="submit" disabled>
                  Boss Fight
                </Button>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col
                span={14}
                style={{
                  textAlign: "justify",
                  textJustify: "inter-word",
                  background: "#F5F5F5",
                  padding: "2vh",
                  border: "1px solid #4CBB7F",
                }}
              >
                {moduleText}
              </Col>
              <Col span={10}>
                {/* Slideshow of sample Images */}
                <div className="carousel-box">
                    <Carousel effect="fade" autoplay ref={carouselRef}>
       
                    {moduleSampleImageUrls?.map((imageURL, index) => {
                      console.log("index",moduleSampleUrls[index]);
                        return (
                        <div key={index}>
                        <div style={{textAlign:"center",padding:"0.5vh"}}>Sample Properties</div>
                        <a href={moduleSampleUrls[index]} target = "_blank" rel="noreferrer">
                          <img
                            style={contentStyle}
                            src={imageURL}
                            alt="sample property "
                          />
                        </a>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button type="text" onClick={() => {carouselRef.current.prev()}}>
                              Previous
                            </Button>
                            <Button type="text" onClick={() => {carouselRef.current.next();}}>
                            Next</Button>
                          </div>
                        </div>
                      );
                      })}
                  </Carousel>
                
                  
                </div>
              </Col>
            </Row>
          </div>
        </Content>
        {/* <FooterBar /> */}
      </Layout>
    </Layout>
  );
};

export default Module;
