import { useParams } from "react-router-dom";
import { Layout, Button, Breadcrumb } from "antd";
import SideNavbar from "../Components/SideNavbar";
import TutorialContent from "../Components/TutorialContent";
const { Content } = Layout;

const Tutorial = () => {
  const { module } = useParams();
  
  return (
    <Layout style={{ minHeight: "100vh", maxHeight: "100vh" }}>
      <SideNavbar />
      <Layout >
      {/* height of content-header is fixed to 14 vh */}
        <Content className="content-header">
        <div style={{ padding: "2vw 2vw 0 1vw" }}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">My games</Breadcrumb.Item>
              <Breadcrumb.Item href={`/${module}`}>{module} </Breadcrumb.Item>
              <Breadcrumb.Item href="/">Tutorial</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ height: 60 , display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <p style={{ fontSize:"x-large",fontWeight:600, margin: 0}}>Tutorials </p>
              <h2>Tutorials </h2>
              <a href = "https://annotations-stage.attentive.ai/?demo=1" target = "_blank" rel="noreferrer"><Button style={{ width: 136 }} type="primary" size="normal">Demo</Button></a>
            </div>
        </div>
        </Content>

      {/* height of content-main is fixed to 86 vh */}
  
        <Content className="content-main">
          <TutorialContent/>
        </Content>
        {/* <FooterBar/> */}
      </Layout>
    </Layout>
  );
};

export default Tutorial;
