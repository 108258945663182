import { Menu,Layout } from 'antd';
import {  MessageOutlined,UserOutlined, HomeOutlined, LogoutOutlined } from '@ant-design/icons';
import { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../Stores/AppStore';
// import sidebarLogo from "../../img/logo-only-dark.svg";
import sidebarLogo from "../../img/Attentive_white_02.png";

const { Sider } = Layout;

const SideNavbar = () => {
  const location = useLocation();
  // eslint-disable-next-line
  const [appStore, appStoreDispatch] = useContext(AppContext);
  const [activeKey] = useState(location.pathname === '/feedback/Residential%20Landscaping' ? ['5'] : ['5']);
  const user= JSON.parse(localStorage.user);
  console.log("user",user);
  const user_name=user.username;
  const userType=user.user_type
  console.log("userType",userType);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  function itemOnClick({item, key, keyPath, domEvent}){
   
    if(key === '1'){
      if (userType==="Player") {
        window.location = '/home';
      }
      else if (userType==="Evaluator") {
        window.location = '/evl-home';
        
      }
    }
    // if(key === '2'){
      // window.location = '/feedback/Residential%20Landscaping';
    // }

    if(key === '4'){
      appStoreDispatch({ type: 'SET_LOGOUT', payload: { isLoggedIn: false } });
      window.location = '/';

    }
  }

  const items = [
    getItem('My Games', '1', <HomeOutlined/>),
    getItem('Feedback', '2', <MessageOutlined />),
  ];

  const items2 = [
    getItem(user_name, '3', <UserOutlined />),
    getItem('Logout', '4', <LogoutOutlined />),
  ];

  return(
    <Sider className="side-navbar" collapsed style={{height:"100vh"}} >
      <div>
        <div className ="top-spacing" style={{ alignItems: 'center',  textAlign: 'center' }}>
          <div>
            <br></br>
            <img style={{height: 32 }} src={sidebarLogo} alt='Attentive Logo' />
          </div>
        </div>
        <br></br>
        <div >
        <Menu style={{background: "#212121", marginTop:50}} theme="dark" selectedKeys={activeKey} mode="inline" items={items} onClick= {itemOnClick}/>
        <Menu style={{background: "#212121", marginTop:390}}  theme="dark" selectedKeys={activeKey} mode="inline" items={items2} onClick= {itemOnClick}/>
        </div>
      </div>
    </Sider> 
  );
}

export default SideNavbar;