import React, { useContext } from "react";
import { Row, Dropdown, Menu, Layout } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { AppContext } from "../../Stores/AppStore";
import AttentiveLogo from "../../img/logo-white.svg";

const { Header } = Layout;

const HeaderNavbar = () => {
 // eslint-disable-next-line 
  const [appStore, appStoreDispatch] = useContext(AppContext);

  const onLogout = () => {
    appStoreDispatch({
      type: "SET_LOGOUT",
      payload: { isLoggedIn: false },
    });
  };

  const handleMenuClick = (e) => {
    if (e.key === "2") {
      onLogout();
    }
  };

  // console.log("appStore.user",appStore.user);

  // if (appStore.user){
  //   const abcdd=appStore.user
  // console.log("appStore",JSON.parse(abcdd));
  // }

  // console.log("abcd");

  const user_name = JSON.parse(localStorage.user).username;
  // console.log("user_name:---", user_name );
 

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "Signed in as " + user_name,
          key: "1",
          icon: <UserOutlined />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  return (
    <Header style={{ background: "#212121", height: "8vh" }}>
      <Row style={{ height: "inherit" }}>
        <div className="left">
          <a href="/">
            <img src={AttentiveLogo} alt="Attentive Logo" />
          </a>
        </div>
        <div>
          <Dropdown style={{ height: 50, width: 50 }} overlay={menu} placement="bottomLeft">
            <UserOutlined style={{
              padding: 2,
              color: "white",
              background: "#4CBB7F",
              height: 30,
              width: 30,
              fontSize: 22,
              borderRadius: "50%",
            }}/>
          </Dropdown>
        </div>
      </Row>
    </Header>
  );
};

export default HeaderNavbar;