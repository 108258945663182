import React from "react";
import { useParams } from "react-router-dom";
import { Table, Breadcrumb, Row, Col } from "antd";
import NewSideNavbar from "../Components/NewSideNavbar";

function EvaluatorFeedback() {
 
  const { module } = useParams();

  const columns = [
    {
      title: "S.No",
      dataIndex: "name",
      key: "name",
      render: (text) => text,
    },
    {
      title: "Player",
      dataIndex: "player",
      // key: 'name',
      render: (text) => text,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Start date & time",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "End date & time",
      dataIndex: "age",
      key: "age",
    },
  
    {
      title: "Output",
      key: "action",
    },
    {
      title: "Time spent",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Score",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Feedback",
      dataIndex: "age",
      key: "age",
    },
  ];
  
  const data = [
    {
      key: "1",
      player: "John Brown",
      address: "New York No. 1 Lake Park",
      startdate: "09/2/2022, 15:21:01",
      //   starttime: '13:21:01',
      enddate: "08/22/2022",
      //   endtime: '13:25:31',
      playeroutput: "thiswillbealink",
      idealoutput: "thiswillbealink",
      timespent: "4 min 30 sec",
      score: 1,
      feedback: "See the ideal output ...",
    },
    {
      key: "2",
      player: "Tim Green",
      address: "London No. 1 Lake Park",
      startdate: "08/10/2022, 13:21:01",
      //   starttime: '13:21:01',
      enddate: "08/10/2022",
      //   endtime: '13:25:31',
      playeroutput: "thiswillbealink",
      idealoutput: "thiswillbealink",
      timespent: "5 min 30 sec",
      score: 5,
      feedback: "See the ideal output ...",
    },
    {
      key: "3",
      player: "Joe Black",
      address: "Sidney No. 1 Lake Park",
      startdate: "11/8/2022, 11:21:01",
      //   starttime: '13:21:01',
      enddate: "15/8/2022",
      //   endtime: '13:25:31',
      playeroutput: "thiswillbealink",
      idealoutput: "thiswillbealink",
      timespent: "1 min 30 sec",
      score: 7,
      feedback: "See the ideal output ...",
    },
  ];

  return (
    <div style={{ height: "100vh", maxHeight: "100vh", display: "flex" }}>
      <NewSideNavbar />
      <div style={{ background: "#F5E8C7", height: "100%", width: "100%" }}>
        <div
          style={{
            background: "white",
            height: "14%",
            width: "100%",
            margin: "auto",
          }}
        >
          <div
            style={{
              height: "33%",
              border: "1px solid #00000043",
              display: "flex",
              alignItems: "center",
              padding:"01em"
            }}
          >
            <Breadcrumb>
              <Breadcrumb.Item href="/evl-home">Give Feedback</Breadcrumb.Item>
              <Breadcrumb.Item >{module} </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div
            style={{
              height: "67%",
              display: "flex",
              alignItems: "center",
              padding:"01em"

            }}
          >
            Feedback ({module})
          </div>
        </div>
        <div style={{ background: "#AC7088", height: "86%", padding: "1em" }}>
          <Row style={{ background: "#ECCCB2", height: "5%" }}>
            <Col span={8}>filter1</Col>
            <Col>filter2</Col>
          </Row>
          <Row>
            <Table columns={columns} dataSource={data} />
          </Row>
        </div>
      </div>
    </div>
  );
}

export default EvaluatorFeedback;
