import { useParams } from "react-router-dom";
import { Layout, Table, Breadcrumb, Row, Col, DatePicker } from "antd";
import SideNavbar from "../Components/SideNavbar";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const Feedback = () => {
  const { module } = useParams();

  const columns = [
    {
      title: 'S. No',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Player',
      dataIndex: 'player',
      key: 'player',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Start date & time',
      dataIndex: 'startdate',
      key: 'startdate',
      // render: (_, record) => <p>{record.startdate} </p>,
      sorter: (a, b) => new Date(a.startdate) - new Date(b.startdate)

    },
    {
      title: 'End date & time',
      dataIndex: 'enddate',
      key: 'enddate',
      render: (_, record) => <p>{record.enddate} <br></br> {record.endtime}</p>,
    },
    {
      title: 'Player Output',
      dataIndex: 'playeroutput',
      key: 'playeroutput',
      render: (text) => <a href = {text}>{text}</a>,
    },
    {
      title: 'Ideal Output',
      dataIndex: 'idealoutput',
      key: 'idealoutput',
      render: (text) => <a href = {text}>{text}</a>,
    },
    {
      title: 'Time Spent',
      dataIndex: 'timespent',
      key: 'timespent',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.timespent - b.timespent,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      sorter: (a, b) => a.score - b.score,

    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
    },
  ];

  const data = [
    {
      key: '1',
      player: 'John Brown',
      address: 'New York No. 1 Lake Park',
      startdate: '09/2/2022, 15:21:01',
      starttime: '13:21:01',
      enddate: '08/22/2022',
      endtime: '13:25:31',
      playeroutput: 'thiswillbealink',
      idealoutput: 'thiswillbealink',
      timespent: '4 min 30 sec',
      score: 1,
      feedback: 'See the ideal output ...'
    },
    {
      key: '2',
      player: 'Tim Green',
      address: 'London No. 1 Lake Park',
      startdate: '08/10/2022, 13:21:01',
      starttime: '13:21:01',
      enddate: '08/10/2022',
      endtime: '13:25:31',
      playeroutput: 'thiswillbealink',
      idealoutput: 'thiswillbealink',
      timespent: '5 min 30 sec',
      score: 5,
      feedback: 'See the ideal output ...'
    },
    {
      key: '3',
      player: 'Joe Black',
      address: 'Sidney No. 1 Lake Park',
      startdate: '11/8/2022, 11:21:01',
      starttime: '13:21:01',
      enddate: '15/8/2022',
      endtime: '13:25:31',
      playeroutput: 'thiswillbealink',
      idealoutput: 'thiswillbealink',
      timespent: '1 min 30 sec',
      score: 7,
      feedback: 'See the ideal output ...'
    },
  ];
  
  return(
    <Layout style={{ minHeight: "100vh", maxHeight: "100vh" }}>
      <SideNavbar  />
      <Layout>
        <Content className="site-layout-first">
          <div style={{ padding: "2vw 2vw 0 1vw" }}>
          <div style={{ height:"14vh",margin:"auto"}}>
            <Breadcrumb>
              <Breadcrumb.Item href="/evl-home">Give Feedback</Breadcrumb.Item>
              <Breadcrumb.Item >{module} </Breadcrumb.Item>
            </Breadcrumb>
            <h2 style={{height:"8vh",display:"flex",alignItems:"center"}}>
              Feedback ({module})
            </h2>
            </div>
            <Row>
              <Col span = {7}>
                <RangePicker showTime />
              </Col>
              <Col span = {1}></Col>
            </Row>
            <br></br>
            <Table columns={columns} dataSource={data} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Feedback;