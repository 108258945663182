import React, {useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Login from "./LoginPage/Login";
import Home from './MainPage/Home';
import EvaluatorHome from './MainPage/EvaluatorHome';
import EvaluatorFeedback from './MainPage/Modules/EvaluatorFeedback';
import Feedback from './MainPage/Modules/Feedback';
import LayoutBlueprint from './MainPage/Modules/LayoutBlueprint';
import Module from './MainPage/Modules/Module';
import Performance from './MainPage/Modules/Performance';
import PracticeArena from './MainPage/Modules/PracticeArena';
import Tutorial from './MainPage/Modules/Tutorial';
import { AppContext } from './Stores/AppStore';
import EvaluatorPropertyFb from './MainPage/Modules/EvaluatorPropertyFb';

function App() {
  // eslint-disable-next-line
  const [appStore, appStoreDispatch] = useContext(AppContext);

  return (
    
    <Router>
      <Routes>
      <Route path='/' element={<Login/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/evl-home' element={<EvaluatorHome/>} />
        <Route path='/:module/' element={<Module/>}/>
        <Route path='/:module/tutorial' element={<Tutorial/>}/>
        <Route path='/:module/tutorial/1' element={<LayoutBlueprint/>}/>

        <Route path='/:module/practice-mode' element={<PracticeArena/>}/>
        <Route path='/:module/practice-mode/performance' element={<Performance/>}/>
        <Route path='/evl-home/evl-feedback/:module/' element={<Feedback/>}/>
        {/* <Route path='/feedback/:module/' element={<Feedback/>}/> */}

        <Route path='/evl-home/evl-feedback/:module/1' element={<EvaluatorFeedback/>}/>
        <Route path='/evl-home/evl-feedback/:module/property' element={<EvaluatorPropertyFb/>}/>
        <Route path="*" element={<Navigate to="/" replace />} /> 
      </Routes>
    </Router>
  );
}

export default App;
