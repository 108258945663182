import React, { useEffect, useState, useContext } from "react";
import { Layout, Row, Card, Col } from "antd";
import { AppContext } from '../Stores/AppStore';

import { MODULE_API } from "../Constants/Constants";
import SideNavbar from "./Components/SideNavbar";

const { Content } = Layout;

const Home = () => {
  const [moduleData, setModuleData] = useState([]);
    // eslint-disable-next-line
    const [appStore, appStoreDispatch] = useContext(AppContext);

  async function getData() {
    var inputData = await fetch(MODULE_API);
    const APIdata = await inputData.json();
    const data = await APIdata.data;

    setModuleData(data);
    console.log("inputJsonData--", data);
    // const sortedModuleData = await data.sort((a, b) => {
    //   return a.module_id - b.module_id;
    // });
    // console.log("sortedModuleData----",sortedModuleData);
  }

  useEffect(() => {
    getData();
  }, []);
  console.log("appStore",appStore);
  return (
    <Layout className="layout">
      <SideNavbar />

      <Layout >
        <Content className="site-layout-content">
      
              <div className="mygames-header">
              <div className="mygames-header-1">
              <h1 className="mygames-h1">
                  WELCOME TO THE GAME OF CARTOS !!!!
                </h1>
                <h2 className="black mygames-h2 ">Let the Games Begin !!!</h2>
              </div>
                <h2 className="mygames-h2 red">MY GAMES</h2>
              </div>

              <Row justify="center" style={{background:"white",margin:"0 10vh"}} gutter={[20,26]}>
                {moduleData.map((item, index) => {
                  return (
                    <Col
                      xs={{
                        span: 24,
                      }}
                      sm={{
                        span: 12,
                      }}
                      lg={{
                        span: 8,
                      }}
                      key={index}
                    >
                      <a href={`/${item.module_name}`}>
                        <Card
                          type="inner"
                          title={item["module_name"]}
                          style={{border:"1px solid",margin:"5vh"  }}
                          bodyStyle={{ padding: "0", alignContent: "center" }}
                          headStyle={{
                            background: "#4CBB7F",
                            textAlign: "center",
                            color: "white",
                            fontSize: "16px"
                          }}
                        >
                          <img
                            src={item["module_image_url"]}
                            alt="background"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "auto",
                              padding: "0px",
                            border:"1px solid"
                            }}
                          />
                          <div className="module-description">
                            {item["module_description"]}
                          </div>
                        </Card>
                      </a>
                    </Col>
                  );
                })}
              </Row>
        </Content>
        {/* <FooterBar /> */}
      </Layout>
    </Layout>
  );
};

export default Home;
