/**
 * Message constants
 */
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const ERROR = 'ERROR';
export const ERROR_MESSAGE_404 = 'URL Not Found.';
export const ERROR_MESSAGE_500 = 'Internal Server Error.';
export const ERROR_SOMETHING_WRONG = 'Opps! Something went wrong.';
export const ERROR_CODES = {
    1: 'Test'
};

// export const LOGIN_API ="http://127.0.0.1:8080/loginuser/";
// export const MODULE_API ="http://127.0.0.1:8080/moduleData/";
// export const TUTORIAL_API="http://127.0.0.1:8080/tutorialContent/"


export const LOGIN_API ="https://staging.hatch-back.attentive.ai/loginuser/";
export const MODULE_API ="https://staging.hatch-back.attentive.ai/moduleData/";
export const TUTORIAL_API="https://staging.hatch-back.attentive.ai/tutorialContent/"