import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../Stores/AppStore";
import { Menu, Layout } from "antd";
import {
  MessageOutlined,
  UserOutlined,
  HomeOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import sidebarLogo from "../../img/Attentive_white_02.png";
const { Sider } = Layout;

function NewSideNavbar() {
  const location = useLocation();
  // eslint-disable-next-line
  const [appStore, appStoreDispatch] = useContext(AppContext);
  const [activeKey] = useState(
    location.pathname === "/feedback/Residential%20Landscaping" ? ["5"] : ["5"]
  );
  const user_name = JSON.parse(localStorage.user).username;

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  function itemOnClick({ item, key, keyPath, domEvent }) {
    if (key === "1") {
      window.location = "/";
    }
    // if(key === '2'){
    // window.location = '/feedback/Residential%20Landscaping';
    // }

    if (key === "4") {
      appStoreDispatch({ type: "SET_LOGOUT", payload: { isLoggedIn: false } });
    }
  }
  const topMenuItems = [
    getItem("My Games", "1", <HomeOutlined style={{fontSize:"1.5em"}} />),
    getItem("Feedback", "2", <MessageOutlined style={{fontSize:"1.5em"}}/>),
  ];
  const bottomMenuItems = [
    getItem(user_name, "3", <UserOutlined style={{fontSize:"1.5em"}} />),
    getItem("Logout", "4", <LogoutOutlined style={{fontSize:"1.5em"}}/>),
  ];
  return (
      <Sider className="side-navbar" collapsed style={{height:"100%"}}>
    <div
      style={{
        width: "80px",
        background: "#212121",
        height: "100%",
        padding:"0.5em", 
        maxHeight:"100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ background: "#212121",alignItems: 'center',  textAlign: 'center' }}>
        <a href="/">
          <img style={{ height: "3em", 
          marginTop:"1em",
          marginLeft:"10px"
        //   display:"block",
        //   marginLeft:"auto",
        //   marginRight:"auto",

          }} src={sidebarLogo} alt="Attentive Logo" />
        </a>
        <Menu
          style={{ background: "#212121", marginTop: "2em" }}
          theme="dark"
          mode="inline"
          items={topMenuItems}
        ></Menu>
      </div>
        <Menu
          style={{ background: "#212121", marginBottom: "40px"}}
          theme="dark"
          selectedKeys={activeKey}
          mode="inline"
          items={bottomMenuItems}
          onClick={itemOnClick}
        />
    </div>
    </Sider>

  );
}

export default NewSideNavbar;
