import React from "react";
import {
  Row,
  Col,
} from "antd";
function LayoutBlueprint() {
  // const [view, setView] = useState("Pdf");
  // const view="Video";
  // const view="Pdf";
  const view="Both";


  //   return (
  //     <div
  //       style={{
  //         background: "grey",
  //         height: "100vh",
  //         padding: "0.5em",
  //         float:"left",
  //         width:"100%"
  //       }}
  //     >
  //       <div
  //         style={{
  //           background: "white",
  //           height: "100%",
  //           width: "80px",
  //           //   width: "10%",
  //         }}
  //       >
  //         SideNavbar
  //       </div>

  //       <div
  //         style={{
  //           background: "orange",
  //           padding: "0.5em",
  //           height: "100%",
  //           //   float: "left",
  //           width:"50%"
  //         }}
  //       >
  //         content
  //       </div>
  //     </div>
  //   );

  return (
    <div
      style={{
        background: "grey",
        height: "100vh",
        padding: "0.5em",
        boxSizing: "border-box",
        display: "flex",
      }}
    >
      {/* sidenavbar */}
      <div
        style={{
          background: "#876445",
          height: "100%",
          width: "80px",
        }}
      >
        SideNavbar
      </div>
      {/* mainbody */}
      <div
        style={{
          background: "orange",
          padding: "0.5em",
          paddingBottom:"0",
          boxSizing: "border-box",
          height: "100%",
          width: "100%",
        }}
      >
        {/* header */}
        <div style={{ background: "#94B49F", height: "14%" }}>Header</div>
        {/* content body */}
        <div
          style={{
            background: "black",
            height: "86%",
            display: "flex",
            padding: "0.5em 0 0 0.5em",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{ background: "#ECCCB2", height: "100%", width: "200px" }}
          >
            inner sider
          </div>
          <div style={{ background: "#F37878", padding: "0.5em 0 0 0.5em",boxSizing: "border-box",width: "100%" }}>
            <div style={{ height: "10%", background: "#E4D192" }}>Heading</div>
            <Row
            style={{
                background: "blue",
                width: "100%",
                height: "90%",
                padding: "0.5em 0 0.5em 0.5em",
                boxSizing: "border-box",
                display: "flex",
              }}
            >
              <Col
                span={view === "Video" ? 24 : view === "Both" ? 14 : 0}
                style={{ background: "white", width: "100%", padding: "0.5em",boxSizing: "border-box", maxHeight: "100%" }}
              >
                <div style={{ background: "#E2DCC8", maxHeight: "100%",aspectRatio:"16/9" }}>
                  video player
                </div>
              </Col>
              <Col
              span={view === "Pdf" ? 24 : view === "Both" ? 10 : 0}
              style={{ background: "#AC7088", width: "40%",padding:"0.5em"}}
              >
               pdf 
              </Col>
            </Row>
            {/* <div
              style={{
                background: "green",
                width: "100%",
                height: "90%",
                padding: "0.5em 0 0 0.5em",
                boxSizing: "border-box",
                display: "flex",
              }}
            > */}
            
            {/* video column */}
              {/* <div
                style={{ background: "white", width: "100%", padding: "0.5em",boxSizing: "border-box" }}
              >
                <div style={{ background: "#E2DCC8", maxHeight: "100%",aspectRatio:"16/9" }}>
                  video player
                </div> 
               </div> */}
              {/* pdf column */}
              {/* <div style={{ background: "#AC7088", width: "40%",padding:"0.5em"}}>pdf</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutBlueprint;
