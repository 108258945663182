import React from "react";
import {  Button } from "antd";

function LevelCard() {
  return (
    <div className="level-card">
      <div className="level-card-title green-text">Level 1</div>
      <div className="level-card-body">
        <p>Best Score: 80/100</p>
        <p>Least Score: 80/100</p>
        <p>Last Score: 80/100</p>
        <div style={{ color: "blue", textAlign: "left" }}>Performance</div>
      </div>
      <div style={{ width: "inherit" }}>
        <Button style={{ width: "98%" }} type="primary">
          GO!!
        </Button>
      </div>
    </div>
  );
}

export default LevelCard;

