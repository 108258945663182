import React, { useState } from "react";
import { useParams } from "react-router-dom";
// eslint-disable-next-line
import { Layout, Row, Col, Breadcrumb, Select, Button } from "antd";
import SideNavbar from "../Components/SideNavbar";

const { Content } = Layout;
// const { Option } = Select;

function EvaluatorPropertyFb() {
  const { module } = useParams();
  const performance_dummy_data = require("../../Data/PerformanceData.json");
  const dummy_data =
    performance_dummy_data.commercial_landscaping_practice.level_1.trial_1
      .property_2.lens_url;
  // eslint-disable-next-line
  const [lensUrl, setLensUrl] = useState("");
  // also define usesatate const [lensURL, setLensURL] = useState("");
  // setLensURL(performance_dummy_data.commercial_landscaping_practice.level_1.trial_1.property_1.lens_url);
  console.log("lensURL", dummy_data);

  
//   const performanceDetails = {
//     propertyScore: 8,
//     accuracy: "good",
//     ml_usage: "ok",
//     timeSpent: "bad",
//     time_taken: 35,
//     ideal_timeRange: "5 to 10 mins",
//   };

  return (
    <Layout style={{ minHeight: "100vh", maxHeight: "100vh" }}>
      <SideNavbar />
      <Layout>
        <Content className="site-layout-first">
          <div style={{ padding: "2vw 2vw 0 1vw" }}>
            <Breadcrumb>
              <Breadcrumb.Item href="/evl-home">Feedback</Breadcrumb.Item>
              <Breadcrumb.Item href={`/evl-feedback/${module}`}>{module}</Breadcrumb.Item>
           
            </Breadcrumb>
            <br></br>
            <h1 className="performance-h1">Performance ({module})</h1>
          </div>
          <div>
            <Row style={{ height: "58vh" }}>
              <Col span={6} style={{ padding: 10, backgroundColor: "#F5F5F5" }}>
                {/* <p className="performance-h2"> Performance details</p> */}
                <div className="performance-h2"> Performance details</div>
                <div>
                  <table style={{ width: "100%" }}>
                    <colgroup>
                      <col />
                      <col
                        style={{ textAlign: "end", justifyContent: "flex-end" }}
                      />
                    </colgroup>
                    <tr>
                      <td>Property score</td>
                      <td>
                        <div
                          style={{
                            height: "25px",
                            background: "#FFA500",
                            padding: 3,
                            borderRadius: "5px",
                            color: "white",
                            display: "inline",

                            // padding:"5px"
                          }}
                        >
                          8/10
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Accuracy</td>
                      <td>
                        {" "}
                        <div
                          style={{
                            height: "25px",
                            background: "#3BB44A",
                            padding: 3,
                            color: "white",
                            borderRadius: "5px",
                            display: "inline",
                          }}
                        >
                          Good
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>ML usage</td>
                      <td>
                        <div
                          style={{
                            height: "25px",
                            background: "#FFA500",
                            padding: 3,
                            borderRadius: "5px",
                            color: "white",
                            display: "inline",
                          }}
                        >
                          ok
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Time spent</td>
                      <td>
                        <div
                          style={{
                            height: "25px",
                            background: "#E04F4F",
                            padding: 3,
                            borderRadius: "5px",
                            color: "white",
                            display: "inline",
                          }}
                        >
                          bad
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Time taken: 35 min</td>
                    </tr>
                    <tr>
                      <td>Ideal time range: 5 to 10 min</td>
                    </tr>
                  </table>
                  <hr style={{ border: "1px solid", opacity: 0.26 }} />
                <div className="performance-h2"> Feedback </div>
                <form>
                    <textarea  style={{maxHeight:"200px",width:"100%"}}/>
                    <Button>
                    <label for="attachments">
                    Add Attachment
                    </label>
                    </Button>
                    <input type="file" style={{visibility:"hidden"}} id="attachments"  multiple />
                </form>
                      
                        {/* <input type="file"  multiple>Add Attachment</input> */}
                          
                  <table style={{ marginTop: 50 }}>
                    <tr>
                      <td>
                        <div className="performance-red-dot" />
                      </td>
                      <td>Score 1 to 3 (Bad)</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="performance-orange-dot" />
                      </td>
                      <td>Score 1 to 3 (Ok)</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="performance-green-dot" />
                      </td>
                      <td>Score 1 to 3 (Good)</td>
                    </tr>
                  </table>
                </div>
              </Col>
              <Col span={18} style={{ padding: "10px" }}>
                {/* <iframe src="https://lens.attentive.ai/shared-view?id=62b197dddb71a016e4aa92da" title="Lens Link" frameBorder={0} allowfullscreen sandbox> */}
                <iframe
                  src={dummy_data}
                  title="Lens Link"
                  frameBorder={0}
                  allowfullscreen
                  sandbox
                >
                  <p>
                    <a href="https://lens.attentive.ai/shared-view?id=62b197dddb71a016e4aa92da">
                      Fallback link for browsers that don't support iframes
                    </a>
                  </p>
                </iframe>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default EvaluatorPropertyFb;
