import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TUTORIAL_API } from "../../Constants/Constants";
import {
  Menu,
  Spin,
  Button,
  Layout,
  Radio,
  Row,
  Col,
  // Progress,
  RadioChangeEvent,
} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
const { Content, Sider } = Layout;

const TutorialContent = () => {
  const { module } = useParams();
  var moduleID;
  if (module === "Landscaping and Snow Industry") {
    moduleID = "M1";
  } else if (module === "Paving Industry") {
    moduleID = "M2";
  } else if (module === "One Carto Platform(OCP)") {
    moduleID = "M3";
  }

  const ModuleAPIurl = TUTORIAL_API + moduleID;
  console.log("ModuleAPIurl", ModuleAPIurl);
  const [tutorialData, setTutorialData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [collapsed, setCollapsed] = useState(false);

  const [videoURL, setVideoURL] = useState("");
  const [pdfURL, setPdfURL] = useState("");
  const [chapterHeading, setChapterHeading] = useState("");
  const [view, setView] = useState("Both");

  async function getData() {
    setLoading(true);
    var inputData = await fetch(ModuleAPIurl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const APIdata = await inputData.json();
    const data = await APIdata.data;
    const inputJsonData = await JSON.parse(data);
    const sortedChapterData = await inputJsonData.sort((a, b) => {
      return a.chapter_number - b.chapter_number;
    });
    setTutorialData(sortedChapterData);
    setChapterHeading("Chapter 1 : " + sortedChapterData[0].chapter_name);
    setVideoURL(sortedChapterData[0].chapter_video_url);
    setPdfURL(sortedChapterData[0].chapter_pdf_url);
    console.log("sortedChapterData", sortedChapterData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  function itemOnClick({ item, key, keyPath, domEvent }) {
    tutorialData.forEach((chapter) => {
      if (Number(key) === chapter.chapter_number) {
        setPdfURL(chapter.chapter_pdf_URL);
        setVideoURL(chapter.chapter_video_URL);
        chapterItems.forEach((item) => {
          if (Number(key) === item.key) {
            setChapterHeading(item.label);
          }
        });
      }
    });
    console.log("chapter_pdfURL", pdfURL);
  }

  var chapterItems = [];
  tutorialData.forEach((chapter) => {
    chapterItems.push({
      key: chapter.chapter_number,
      label: "chapter " + chapter.chapter_number + " : " + chapter.chapter_name,
    });
  });

  if (loading) {
    return (
      <Layout>
        <div className="loading">
          <Spin tip="Loading..." />
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <Sider
        className="chapter-sider"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
          <div
            className="top-spacing"
            style={{
              // height: "calc(8vh - 4px)",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-around",
              height:"7vh"
            }}
          >
            <h1
              style={{
                color: "#212121",
                fontSize: "medium",
                margin: 0,
                padding: "4px 0 0 0",
              }}
            >
              {collapsed ? "" : "Chapter List"}
            </h1>
            <Button
              style={{ background: "#4CBB7F" }}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              type="primary"
              shape="circle"
              icon={collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
              size={50}
            />
          </div>
          <Menu
            className="tutorial-chapter-list"
            mode="inline"
            defaultSelectedKeys={["1"]}
            items={collapsed ? "" : chapterItems}
            onClick={itemOnClick}
            />          
      </Sider>
      <Content className="content-main-second">
        <div >
          <Row style={{ marginBottom: "1vh", height:"5vh" }}>
            <Col span={18}>
              <h1
                style={{
                  color: "#212121",
                  fontSize: "medium",
                  margin: 0,
                }}
              >
                {chapterHeading}
              </h1>
            </Col>
            <Col span={1}>View</Col>
            <Col span={5}>
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setView(e.target.value);
                }}
                value={view}
              >
                <Radio value={"Video"}>Video</Radio>
                <Radio value={"Pdf"}>Pdf</Radio>
                <Radio value={"Both"}>Both</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row style={{ minHeight: "78vh" , border:"red 1px solid" }}>
          {/* video column */}
            <Col span={view === "Video" ? 24 : view === "Both" ? 14 : 0} style={{border:"yellow 1px solid", }}>
              <div
                className="video-responsive"
                style={{
                  maxHeight: "100%",aspectRatio:"16/9",
                  // height: view === "Both" ? "75%" : "100%",
                  // // to maintain 16:9, as height is 78 vh width is kept 78x16/9 =138vh
                  // width: view === "Both" ? "100%" : "138vh",
                  // position: "relative",
                }}
              >
                <iframe
                  src={videoURL}
                  sandbox="allow-scripts allow-presentation  allow-same-origin"
                  allowFullScreen={true}
                  width="100%"
                 height="100%"
                  scrolling="no"
                  seamless=""
                  title="Chapter Video"
                  frameBorder="0"
                ></iframe>
              </div>
            </Col>
            {/* pdf column */}
            <Col
              span={view === "Pdf" ? 24 : view === "Both" ? 10 : 0}
              style={{ paddingLeft: 10 }}
            >
              <iframe
                sandbox="allow-scripts allow-presentation allow-same-origin"
                title="Chapter pdf"
                allowFullScreen={true}
                src={pdfURL}
                width="100%"
                height="100%"
                allow="autoplay"
              ></iframe>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default TutorialContent;
